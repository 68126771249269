import React, {useEffect, useRef, useState} from "react";
import ReactHtmlParser from "react-html-parser";
import ObjetivaEnsinaMais from "../../../components/TesteConhecimentos/Tipos/ObjetivaEnsinaMais";
import "./AtividadeSimples.css";
import TitleEnsinaMais from "../../../components/Title/TitleEnsinaMais";
import useSound from "use-sound";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { Audio_Icon } from "../../../svg_files/audio_icon";

const AtividadeSimples = (props) => {
  const { shouldUseAudio } = props;
  const { lesson } = props;
  const { mode } = props;
  const { otherData } = props;
  const { titleProps } = props;
  const { questionSound } = props;
  const [playSound] = useSound(questionSound);
  const { questionTitle } = props;
  const { hasQuestions } = props;
  const { introContent } = props;
  const { singular } = props;



  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);


  const requestAudio = async () => {

    const stripHTML = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };
    
    const rawText = stripHTML(questionTitle); // Remove tags HTML
    const requestBody = {
      text: rawText, // Passa o texto puro (sem HTML) para gerar o áudio
    };
    

    const baseURL = `${window.location.protocol}//${window.location.hostname + (window.location.port ? ':5000' : '')}/api`;

    try {
      const response = await fetch(baseURL + "/requestAudio/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer pR889Q6+6CI9VuF",
          accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Erro na solicitação de áudio");
      }

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      setAudioUrl(audioUrl);
    } catch (error) {
      console.error("Erro ao buscar o áudio:", error);
    }
  };


  const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    // audioRef.current.playbackRate = speed;

    if (autoPlay && audioUrl) {
      if (shouldUseAudio)
        audioRef.current.play();
      // setIsPlaying(true);
    }
  }, [autoPlay, audioUrl ]);


  useEffect(() => {

    if (audioRef.current) {
      requestAudio()
    }
    
  }, [mode]);

  return (
    <div
      className="container_atividade_simplesEnsinaMais"
      style={{ width: singular ? "70%" : "48%" }}
    >
      <div className="container_pergunta" style={{}}>
        <div className="pergunta" style={{}}>
          {ReactHtmlParser(
            questionTitle !== null ? questionTitle.toUpperCase() : ""
          )}
        </div>
        {/* {console.log("VEIO ESTE SOM: ", questionSound)} */}
        {questionSound && (
            <>
              <div className="caixa_som_pergunta" onClick={() => audioRef?.current?.play()}>
                <Audio_Icon/>
              </div>
              <audio ref={audioRef} src={audioUrl} style={{display: "none"}}/>
            </>
        )}
      </div>

      <div
        style={{
          paddingTop: "10vh",
          width: "100%",
        }}
      >
        {hasQuestions ? (
          <div style={{}} className="container_perguntas">
            {" "}
            <ObjetivaEnsinaMais props={otherData}  />
          </div>
        ) : (
          <div style={{}} className="container_embed">
            {" "}
            {ReactHtmlParser(introContent)}
          </div>
        )}
      </div>
    </div>
  );
};

export default AtividadeSimples;
