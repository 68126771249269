/* eslint-disable react/jsx-pascal-case */
import {
  faAngleLeft,
  faAngleRight,
  faExclamation,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Check as CheckIcon } from "tabler-icons-react";
import Capa from "../../components/MaoNaMassa/Capa";
import TC_perguntas from "../../components/TesteConhecimentos/TC_perguntas";
import Title from "../../components/Title/Title";
import Anotacoes from "../../components/_Anotacoes/Anotacoes";
import ProgressBar from "../../components/_ProgressBar/ProgressBar";
import loadingGif from "../../Files/loading.gif";
import { getCourses, submitResposta } from "../../services";
import Context from "../../state/Context";
import { useBaseURLs, useCourse, useTheme } from "../../state/Provider";
import img_continuar from "./continuar.png";
import "./MaoMassa.css";
import fundo from "../../Files/bg_geral.png";

Axios.defaults.headers.common["Authorization"] = `Bearer ${"pR889Q6+6CI9VuF"}`;

export function BaseURL() {
  const { baseURL } = useBaseURLs();
  return baseURL;
}
const baseURL = BaseURL();

function MaoMassa(props) {
  const categoria = 2;

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [iconFullScreen, setIconFullScreen] = useState(faExpandArrowsAlt);

  function openFullScreen() {
    setIsFullScreen(true);
    setIconFullScreen(faCompressArrowsAlt);
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }
  }
  function closeFullScreen() {
    setIsFullScreen(false);
    setIconFullScreen(faExpandArrowsAlt);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }

  const [userID, setUserID] = useState("");
  const [lessonID, setLessonID] = useState("");
  const [courseID, setCourseID] = useState("");
  const [progress, setProgress] = useState(0);
  var [isActive, setIsActive] = useState();
  const [textResponse, setTextResponse] = useState("");

  var contador = null;
  const { courseName, setCourseName, lesson, setLesson } = useCourse();

  const context = useContext(Context);
  const { theme, setTheme } = useTheme("black");
  const [mode, setMode] = useState(0);
  const [selectedAlternative, setSelectedAlternative] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [confirmouGeral, setConfirmouGeral] = useState(false);
  const [temAnotacao, setTemAnotacao] = useState(false);
  const [anotacaoVindaDoToken, setAnotacaoVindaDoToken] = useState("");
  const [indexAnotacao, setIndexAnotacao] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modalRespondido, setModalRespondido] = useState(false);
  const [UrlArquivosAtividadePratica, setUrlArquivosAtividadePratica] =
    useState("");
  const [UrlApontamentos, setUrlApontamentos] = useState("");
  const [UrlEncerramentoAula, setUrlEncerramentoAula] = useState("");
  let tela = false;
  let urlListarAnotacao = null;

  const [telaInicial, setTelaInicial] = useState(0);

  if (
    localStorage.getItem("tela") !== undefined &&
    localStorage.getItem("tela") !== null
  ) {
    tela = parseInt(localStorage.getItem("tela"));
  }

  const [showingModal, setShowingModal] = useState(false);

  var root = document.querySelector(":root");
  root.style.setProperty("--backGroundImage", `url(${fundo})`);

  useEffect(() => {
    const { token } = props;
    const { themeGeral } = props;
    const { setarCor } = props;
    const { setFiltro } = props;
    setUrlArquivosAtividadePratica(token.UrlArquivosAtividadePratica);
    setUrlApontamentos(token.UrlApontamentos);
    setUrlEncerramentoAula(token.UrlEncerramentoAula);
    // console.log("TOKEN:", token.UrlArquivosAtividadePratica);

    setTheme(themeGeral);

    getCourses(token.IdMateria, token.IdAula, categoria)
      .then((response) => {
        if (token.IdRegiao === 1) {
          setarCor(response.data[0].cor1, response.data[0].bg1);
          setTheme(response.data[0].cor1);
          setFiltro(response.data[0].filtro1);
        }
        if (token.IdRegiao === 2) {
          setarCor(response.data[0].cor2, response.data[0].bg2);
          setTheme(response.data[0].cor2);
          setFiltro(response.data[0].filtro2);
        }
        if (token.IdRegiao === 3) {
          setarCor(response.data[0].cor3, response.data[0].bg3);
          setTheme(response.data[0].cor3);
          setFiltro(response.data[0].filtro3);
        }
        if (token.IdRegiao === 4) {
          setarCor(response.data[0].cor4, response.data[0].bg4);
          setTheme(response.data[0].cor4);
          setFiltro(response.data[0].filtro4);
        }

        setIsActive(response.data[0].Lessons.active);
        setLesson(response.data[0].Lessons);
        setCourseName(response.data[0].courseName);
        setLoaded(true);
        setUserID(token.IdAluno);
        setLessonID(response.data[0].Lessons.idLesson);
        setCourseID(response.data[0].idCourse);
      })
      .then(() => {
        setTelaInicial(token.Tela);
        setModalRespondido(false);
      });

    urlListarAnotacao = token.UrlAnotacaoListar;

    let bodyListar = JSON.stringify({
      CodigoContrato: JSON.parse(token.JsonBodyAnotacaoListar).CodigoContrato,
      Tipo: JSON.parse(token.JsonBodyAnotacaoListar).Tipo,
      Parceiro: JSON.parse(token.JsonBodyAnotacaoListar).Parceiro,
    });

    fetch(baseURL + "anotacao", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pR889Q6+6CI9VuF",
      },
      body: JSON.stringify({
        url: urlListarAnotacao,
        bodyAnotacao: bodyListar,
        tipo: "listar",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setIndexAnotacao(() => {
          if (data.Dados.length !== 0) {
            setTemAnotacao(true);
            return data.Dados[data.Dados.length - 1].idBlocoNota;
          } else {
            return null;
          }
        });

        let anotacao =
          (data.Dados.length !== 0 &&
            data.Dados[data.Dados.length - 1].Descricao) ||
          "";
        if (anotacao !== null) {
          localStorage.setItem(`anotacoes`, anotacao);

          setAnotacaoVindaDoToken(anotacao);
        }
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("tela", mode);
    if (mode > 0) {
      props.turnOffCapa();
    } else {
      props.turnOnCapa();
    }
  }, [mode]);

  useEffect(() => {
    document.title = lesson.lessonName;
  }, [lesson.lessonName]);

  const previous = () => {
    setMode(parseInt(mode) - 1);
  };
  const next = () => {
    if (isActive) {
      if (mode < lesson.Questoes.length + 1) {
        setMode(parseInt(mode) + 1);
        localStorage.setItem(
          "tela",
          parseInt(localStorage.getItem("tela")) + 1
        );
      } else {
      }
    }
  };

  const [sending, setSending] = useState(false);

  const submitFile = (file) => {
    // console.log("FILE: ", file);

    var extensao = file.name.split(/\.(?=[^\.]+$)/),
      reader = new FileReader();
    reader.readAsDataURL(file);
    // console.log(file)

    const post = function (base64) {
      setSending(true);

      // console.log("o que esta indo de fato: ", base64);

      const myInit = {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Authorization",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CodigoContratoCursoMateria: userID,
          Nome: file.name,
          // "Nome": file.name + "." + extensao[1],
          CaminhoFTP: (+new Date()).toString(36) + "." + extensao[1],
          Extensao: extensao[1],
          ArquivoBase64: base64,
        }),
      };
      // console.log(UrlArquivosAtividadePratica);
      Axios({
        method: "post",
        data: myInit.body,
        url: UrlArquivosAtividadePratica,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
        .then((response) => {
          // console.log("RESPONSE: ", response);
          if (response.data.success === true) {
            // console.log(myInit.body);
            context.setUploadSuccessful(true);
          } else {
            context.setUploadSuccessful(false);
          }
          setSending(false);
        })
        .catch(function (err) {
          if (err) {
            // console.log("ERRO: ", err);
          }
        });
    };

    reader.onloadend = function () {
      var b64 = reader.result.replace(/^data:.+;base64,/, "");
      // console.log("Reader: ", reader);
      // console.log("B64: ", b64);
      post(b64);
    };
  };

  const resetLocalStorage = () => {
    setModalRespondido(true);
    localStorage.setItem("tela", 1);
    localStorage.clear();
    setMode(1);
  };
  const [modalToggle, setModalToggle] = useState(false);
  const enableModal = () => {
    if (modalToggle === true || telaInicial <= 1) {
      next();
    } else {
      setModalToggle(true);
      setShowingModal(true);
    }
  };

  const [showImageZoom, setShowImageZoom] = useState(false);
  const [imageFile, setImageFile] = useState();
  const toggleImageZoom = (estado, file) => {
    setImageFile(file);
    setShowImageZoom(estado);
  };

  const saveTela = () => {
    var url = UrlApontamentos.replace("##Tela", "" + mode);
    if (mode >= 1) {
      Axios.post(url, {
        tela: mode,
      });
    }
  };

  const Finalizar = () => {
    let url = UrlApontamentos.replace("##Tela", parseInt(mode)) + "/1";
    fetch(url, {
      method: "POST",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.type == "opaque") {
          localStorage.clear();

          window.location.assign(UrlEncerramentoAula);
        }
      })
      .catch(function (err) {
        if (err) {
          // console.log("ERRO: ", err)
        }
      });
  };

  const saveTelaFinalizando = () => {
    let url = UrlApontamentos.replace("##Tela", parseInt(mode)) + "/1";
    // console.log(url)

    fetch(url, {
      method: "POST",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.type == "opaque") {
          localStorage.clear();
          // console.log("redirecionando")
          // window.location.assign(UrlEncerramentoAula)
          setIsLoading(false);
          window.location.assign(
            "https://sistemas.moveedu.com.br/hub/EncerramentoAula"
          );
        }
      })
      .catch(function (err) {
        if (err) {
          // console.log("ERRO: ", err)
        }
      });
  };

  useEffect(() => {
    context.setModeContext(mode);
  }, [mode]);

  let urlInserirAnotacao = null;
  let urlAlterarAnotacao = null;

  useEffect(() => {
    const { token } = props;
    urlInserirAnotacao = token.UrlAnotacaoInserir;
    urlAlterarAnotacao = token.UrlAnotacaoAlterar;

    let bodyInserirAnotacao = JSON.stringify({
      CodigoContrato: JSON.parse(token.JsonBodyAnotacaoInserir).CodigoContrato,
      CodigoMateria: JSON.parse(token.JsonBodyAnotacaoInserir).CodigoMateria,
      Tipo: JSON.parse(token.JsonBodyAnotacaoInserir).Tipo,
      Parceiro: JSON.parse(token.JsonBodyAnotacaoInserir).Parceiro,
      Titulo: JSON.parse(token.JsonBodyAnotacaoInserir).Titulo,
      Descricao: localStorage.getItem("anotacoes"),
      IdAula: token.IdAula,
      IdAtividade: token.IdAtividade,
    });
    let bodyAlterarAnotacao = JSON.stringify({
      idBlocoNota: parseInt(indexAnotacao),
      Parceiro: JSON.parse(token.JsonBodyAnotacaoAlterar).Parceiro,
      Titulo: JSON.parse(token.JsonBodyAnotacaoAlterar).Titulo,
      Descricao: localStorage.getItem("anotacoes"),
    });
    if (temAnotacao) {
      if (
        localStorage.getItem("anotacoes") !== null &&
        localStorage.getItem("anotacoes") !== anotacaoVindaDoToken
      ) {
        fetch(baseURL + "anotacao", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pR889Q6+6CI9VuF",
          },
          body: JSON.stringify({
            url: urlAlterarAnotacao,
            bodyAnotacao: bodyAlterarAnotacao,
            tipo: "alterar",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setTemAnotacao(true);
          });
      }
    } else {
      if (indexAnotacao === null) {
        fetch(baseURL + "anotacao", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pR889Q6+6CI9VuF",
          },
          body: JSON.stringify({
            url: urlInserirAnotacao,
            bodyAnotacao: bodyInserirAnotacao,
            tipo: "inserir",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setTemAnotacao(true);
            setIndexAnotacao(data.Dados.idNota);
          });
      }
      // }
    }
  }, [context.isFocusAnnotation]);

  window.onbeforeunload = function () {
    if (mode !== lesson.Questoes.length + 1) {
      saveTela();
      return 1;
    }
  };

  var imgdiv;


  const [mobile, setMobile] = useState("");
  useEffect(() => {
    setMobile(() => {
      if (mode >= 1) {
        return "main-mobile";
      }
    });
  }, [mode]);
  const confirmGeral = () => {
    localStorage.removeItem("anotacoes");
    localStorage.removeItem("tela");

    let i = 0;
    Object.values(localStorage).forEach((element, index) => {
      if (
        JSON.parse(element).resposta !== undefined &&
        lessonID === JSON.parse(element).lessonID &&
        JSON.parse(element).userID === userID &&
        JSON.parse(element).category === categoria
      ) {
        i++;
      }
    });
    let numUploads = 0;
    for (let i = 0; i < lesson.Questoes.length; i++) {
      if (lesson.Questoes[i].questionType === 10) {
        numUploads++;
      }
    }

    if (i === lesson.Questoes.length - numUploads) {
      setIsLoading(true);
      submitResposta({
        lessonIDatual: lessonID,
        userID: userID,
        localStorage: Object.values(localStorage),
        categoria: categoria,
      }).then((response) => {
        saveTelaFinalizando();
      });
    } else {
      alert("Você não respondeu todas as questões!");
    }
  };

  const modalStruct = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-container">
          <img style={{ width: "160px" }} src={img_continuar} alt="" />
          <p className="modal-texto" style={{ marginTop: "20px" }}>
            Deseja continuar de onde parou?
          </p>

          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
              flex: "3.5",
            }}
          >
            <div
              onClick={() => {
                setMode(telaInicial);
                setModalRespondido(true);
                setShowingModal(false);
              }}
              className="modal-btn-sim"
            >
              Sim
            </div>
            <div
              onClick={() => {
                resetLocalStorage();
                setShowingModal(false);
              }}
              className="modal-btn-nao"
            >
              Não
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="teste-conhecimentos">
        <div hidden={false} id="modal">
          {loaded && <>{modalToggle && !modalRespondido && modalStruct()}</>}
        </div>

        {mode !== 0 && (
          <Title props={{ theme, courseName, lesson, lesson, contador }} />
        )}

        {loaded ? (
          <>
            {isActive === 1 && lesson.Questoes !== null ? (
              <>
                <button
                  className="btn-fullscreen"
                  onClick={() =>
                    isFullScreen ? closeFullScreen() : openFullScreen()
                  }
                >
                  <FontAwesomeIcon
                    icon={iconFullScreen}
                    style={{ transform: "scale(1.35)" }}
                  ></FontAwesomeIcon>
                </button>
                {mode > 0 && mode < lesson.Questoes.length + 1 ? (
                    <div hidden={!showImageZoom}>
                      <div
                          onClick={() => toggleImageZoom(false)}
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            zIndex: 1000,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                      >
                        <div
                            style={{
                              position: "relative",
                              backgroundColor: "#fff",
                              borderRadius: "25px",
                              padding: "30px",
                              maxWidth: "90%",
                              maxHeight: "90%",
                              overflow: "auto",
                              textAlign: "center",
                            }}
                        >
                          {/* Close Button */}
                          <div
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                          >
                            <button
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  width: "50px",
                                  height: "50px",
                                  padding: "10px",
                                  fontSize: "20px",
                                  backgroundColor: "#ff4757",
                                  color: "#fff",
                                  border: "none",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s ease",
                                }}
                                onClick={() => toggleImageZoom(false)}
                                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#ff6b81")}
                                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#ff4757")}
                            >
                              X
                            </button>
                          </div>

                          {/* Image Container */}
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "30px",
                              }}
                          >
                            <img
                                src={imageFile}
                                alt={"alternativa"}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "80vh",
                                  borderRadius: "10px",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                ) : (
                    <></>
                )}

                <main id="conteudo" className={mobile} style={{display: ""}}>
                  <section className="section-left">
                    <div className="section-left-1 items">
                      <div className="tela-inicial-left">
                        <Capa
                            props={{
                              theme,
                              lesson,
                              courseName,
                              contador,
                              progress,
                              mode,
                            }}
                        />
                      </div>
                      {mode > 0 && (
                          <ProgressBar
                              props={{
                                start: false,
                                theme,
                                mode,
                                lesson,
                                progress,
                              }}
                          />
                      )}
                      {mode > 0 && (
                          <>
                            <Anotacoes></Anotacoes>
                          </>
                      )}
                    </div>

                    <div className="section-left-2 items">
                      {mode === 0 && (
                          <button
                              hidden={showingModal}
                              className="btn-comecar-left"
                              onClick={() => enableModal()}
                          >
                            COMEÇAR
                          </button>
                      )}
                    </div>
                    <div className="all-itens"></div>
                  </section>

                  {mode > 0 && (
                      <>
                        <div className="centro">
                          {isLoading && (
                              <img
                                  style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                              height: "60px",
                            }}
                            src={loadingGif}
                          ></img>
                        )}
                        <div className="centro2">
                          <h1></h1>
                        </div>
                        <div style={{ marginLeft: "5px" }}>
                          <h2
                            style={{
                              zIndex: "5",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              aligItems: "center",
                              color: "white",
                              transition: "all 1s",
                              width: "50px",
                              height: "50px",
                              borderRadius: "50px",
                              background: theme,
                              position: "absolute",
                              marginTop: "55px",
                              fontSize: "20px",
                            }}
                          >
                            {mode !== lesson.Questoes.length + 1 ? (
                              <>{mode}</>
                            ) : (
                              <>
                                {!confirmouGeral &&
                                lesson.mm_avaliativo === 1 ? (
                                  <>
                                    <FontAwesomeIcon
                                      style={{
                                        margin: "auto",
                                        fontSize: "24px",
                                      }}
                                      icon={faExclamation}
                                    ></FontAwesomeIcon>
                                  </>
                                ) : (
                                  <>
                                    <CheckIcon size={48} />
                                  </>
                                )}
                              </>
                            )}
                          </h2>
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    {mode > 0 ? (
                      <>
                        {mode > 0 ? (
                          <>
                            <button
                              className="nav_buttons nav_buttons_left"
                              style={{
                                border: "4px solid rgba(211,211,211,0.3)",
                                zIndex: "4",
                                fontWeight: "800",
                                fontSize: "1.8em",
                                borderRadius: "50%",
                                display: "flex",
                                position: "absolute",
                                justifyContent: "center",
                                background: { theme },
                                textAlign: "center",
                                alignItems: "center",
                              }}
                              onClick={() => previous()}
                            >
                              <FontAwesomeIcon
                                icon={faAngleLeft}
                              ></FontAwesomeIcon>
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                        {mode < lesson.Questoes.length + 1 ? (
                          <>
                            <button
                              className="nav_buttons nav_buttons_right"
                              style={{
                                border: "4px solid rgba(211,211,211,0.3)",
                                zIndex: "4",
                                fontWeight: "800",
                                fontSize: "1.8em",
                                borderRadius: "50%",
                                display: "flex",
                                position: "absolute",
                                justifyContent: "center",
                                background: { theme },
                                textAlign: "center",
                                alignItems: "center",
                              }}
                              onClick={() => next()}
                            >
                              <FontAwesomeIcon
                                icon={faAngleRight}
                              ></FontAwesomeIcon>
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <section className="section-right">
                    <div className={"section-right-mm"}>
                      
                    {mode === 0 && loaded && (
                      <div className="tela-inicial-right tela-inicial-right-mao-na-massa">
                        <Capa
                          props={{
                            theme,
                            lesson,
                            courseName,
                            contador,
                            progress,
                            mode,
                          }}
                        />
                        <div style={{ height: "100%" }}></div>
                        <div className="section-left-2 items">
                          <button
                            className="btn-comecar-right"
                            onClick={() => enableModal()}
                          >
                            COMEÇAR
                          </button>
                        </div>
                      </div>
                    )}

                      {mode <= lesson.Questoes.length ? (
                        <>
                          <TC_perguntas
                            props={{
                              sending,
                              setSending,
                              submitFile,
                              toggleImageZoom,
                              userID,
                              confirmGeral,
                              textResponse,
                              setTextResponse,
                              theme,
                              mode,
                              next,
                              lesson,
                              setSelectedAlternative,
                              selectedAlternative,
                              previous,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {!confirmouGeral && lesson.mm_avaliativo === null ? (
                            <>
                              <div className="tela-final">
                                <div className="tela-final-mensagem">
                                  <p style={{ marginLeft: "40px" }}>
                                    Se você já finalizou as atividades da aula,
                                    pode confirmar clicando neste botão.
                                  </p>
                                </div>
                                <div>
                                  <button
                                    className="btn-comecar-left"
                                    onClick={() => Finalizar()}
                                  >
                                    FINALIZAR
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="tela-final">
                                <div className="tela-final-mensagem">
                                  <p style={{ marginLeft: "40px" }}>
                                    Você pode confirmar o envio de suas
                                    respostas com este botão, ou voltar para
                                    revisar as questões.
                                  </p>
                                </div>
                                <div>
                                  <button
                                    className="btn-comecar-left"
                                    onClick={() => confirmGeral()}
                                  >
                                    ENVIAR RESPOSTAS
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </section>
                </main>
              </>
            ) : (
              <>
                <h1 style={{ color: "red", fontSize: "100px" }}>
                  Questões desativas pelo Admin
                </h1>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default MaoMassa;
