import React, {useState, useRef, useEffect} from "react";
import ReactHtmlParser from "react-html-parser";
import AcesseMM from "../_AcesseMM/AcesseMM";
import Definicao from "../_Definicao/Definicao";
import Dica from "../_Dica/Dica";
import Importante from "../_Importante/Importante";
import "./TC_perguntas.css";
import FileUpload from "./Tipos/FileUpload";
import Objetiva from "./Tipos/Objetiva";
import AudioPlayer from "./AudioPlayer/AudioPlayer";

const TC_perguntas = ({ props }) => {
  const {
    mode,
    lesson,
    selectedAlternative,
    setSelectedAlternative,
    theme,
    submitFile,
    userID,
    questionID,
    respostas,
    setSending,
    sending,
    toggleImageZoom,
      next,
      previous,
  } = props;

  const [audioUrl, setAudioUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [isAudioFetched, setIsAudioFetched] = useState(false); // Para controlar se o áudio já foi buscado
  
  const [audioSpeed, setAudioSpeed] = useState("1")
  const [autoPlay, setAutoPlay] = useState(false)

  const fooRef = useRef(null);

  useEffect(() => {
    if (fooRef.current) {
      const targetDiv = fooRef.current;
      const itemList = Array.from(targetDiv.getElementsByTagName("img"));

      itemList.forEach((element) => {
        targetDiv.addEventListener("click", function (event) {
          if (element === event.target) {
            toggleImageZoom(true, element.src);
          }
        });
      });
    }
  }, [mode]);

  
  return (
      <div>
        {mode !== 0 && (
            <div className="content-questions">
              {lesson.Questoes[mode - 1].category === 1 ? (
                  <>
                    <header className="question-name-tc">
                      <div>
                        <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>
                          {ReactHtmlParser(lesson.Questoes[mode - 1].questionName)}
                        </p>

                        {lesson.Questoes[mode - 1].acesse && (
                            <AcesseMM props={{ lesson, mode }} />
                        )}

                        {lesson.Questoes[mode - 1].dica && <Dica props={{ lesson, mode }} />}

                        {lesson.Questoes[mode - 1].importante && (
                            <Importante props={{ lesson, mode }} />
                        )}

                        {lesson.Questoes[mode - 1].definicao && (
                            <Definicao props={{ lesson, mode }} />
                        )}
                      </div>
                    </header>
                  </>
              ) : (
                  <>
                    <header  className="question-name">
                      <div>
                        <p ref={fooRef} style={{ whiteSpace: "pre-line" }}>

                          <AudioPlayer
                              questionName={lesson.Questoes[mode - 1].questionName}
                              mode={mode}
                          />
                          
                          {ReactHtmlParser(lesson.Questoes[mode - 1].questionName)}

                        </p>

                        {lesson.Questoes[mode - 1].acesse && (
                            <AcesseMM props={{ lesson, mode }} />
                        )}

                        {lesson.Questoes[mode - 1].dica && <Dica props={{ lesson, mode }} />}

                        {lesson.Questoes[mode - 1].importante && (
                            <Importante props={{ lesson, mode }} />
                        )}

                        {lesson.Questoes[mode - 1].definicao && (
                            <Definicao props={{ lesson, mode }} />
                        )}
                      </div>
                    </header>
                  </>
              )}

              <div className="alternatives-container">
                {lesson.Questoes[mode - 1].questionType === 1 && (
                    <Objetiva
                        props={{
                          respostas,
                          userID,
                          questionID,
                          theme,
                          lesson,
                          mode,
                          setSelectedAlternative,
                          selectedAlternative,
                        }}
                    />
                )}
              </div>

              {lesson.Questoes[mode - 1].questionType === 10 && (
                  <FileUpload
                      props={{ mode, theme, userID, submitFile, setSending, sending }}
                  />
              )}

              <span
                  style={{
                    position: "relative",
                    zIndex: "3",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 00px",
                  }}
              ></span>
            </div>
        )}
      </div>
  );
};

export default TC_perguntas;
