import React, { useState, useRef, useEffect } from "react";
import "./AudioPlayer.css";
import {Auto_play} from "../../../svg_files/auto_play";
import {Audio_Icon_Alternative} from "../../../svg_files/audio_icon_alternative";
import {Audio_icon_narration} from "../../../svg_files/audio_icon_narration"; // Pode criar um arquivo CSS para estilizar o player

const AudioPlayer = ({ questionName, mode}) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [speed, setSpeed] = useState(1);
    const [autoPlay, setAutoPlay] = useState(true);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isAudioFetched, setIsAudioFetched] = useState(false); // Para controlar se o áudio já foi buscado


    const stripHTML = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    };
    const requestAudio = async () => {
        const rawText = stripHTML(questionName); // Remove tags HTML
        if(questionName == undefined || questionName == "") return
        const requestBody = {
            text: rawText, // Passa o texto puro (sem HTML) para gerar o áudio
        };

        const baseURL = `${window.location.protocol}//${window.location.hostname + (window.location.port ? ':5000' : '')}/api`;

        try {
            const response = await fetch(baseURL + "/requestAudio/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer pR889Q6+6CI9VuF",
                    accept: "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error("Erro na solicitação de áudio");
            }

            const blob = await response.blob();
            const audioUrl = URL.createObjectURL(blob);
            setAudioUrl(audioUrl);
            setIsAudioFetched(true); // Marca que o áudio foi buscado
        } catch (error) {
            console.error("Erro ao buscar o áudio:", error);
        }
    };
    
    
    const handlePlayPause = async () => {
        if (!audioUrl) {
            await requestAudio(); // Busca o áudio se ainda não foi carregado
        }

        if (audioRef.current.paused) {
            audioRef.current.play();
            setIsPlaying(true);
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleSpeedChange = (e) => {
        const newSpeed = e.target.value;
        setSpeed(newSpeed);
        audioRef.current.playbackRate = newSpeed;
    };

    const handleProgressChange = (e) => {
        const newTime = e.target.value;
        if (audioRef.current.currentTime){
            audioRef.current.currentTime = newTime;
            setProgress(newTime);
        }
    };

    const updateProgress = () => {
        setProgress(audioRef?.current?.currentTime);
    };

    // Efeito para atualizar o progresso e carregar metadados de duração do áudio
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener("timeupdate", updateProgress);
            audioRef.current.addEventListener("loadedmetadata", () => {
                setDuration(audioRef.current.duration);
            });

            return () => {
                audioRef?.current?.removeEventListener("timeupdate", updateProgress);
            };
        }
    }, [audioUrl]);

    // Efeito para controlar o autoplay
    useEffect(() => {
        audioRef.current.playbackRate = speed;

        if (autoPlay && audioUrl) {
            audioRef.current.play();
            setIsPlaying(true);
        }
    }, [autoPlay, audioUrl ]);


    const [lastExecution, setLastExecution] = useState(0);

    useEffect(() => {
        const now = Date.now();

        if (now - lastExecution < 1000) {
            setAutoPlay(false)
            setIsPlaying(false);
            audioRef.current.pause();
            return;
        }

        setLastExecution(now);

        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
            requestAudio();
        }
    }, [mode]);
    
    const on_color =  "#ffffff"
    const off_color = "rgba(255,255,255,0.4)"

    return (
        <div className="audio-player">
            <button onClick={handlePlayPause}>
                {isPlaying ? <Audio_icon_narration color={on_color}/> : <Audio_icon_narration color={off_color}/>}
            </button>

            <input
                type="range"
                value={progress}
                max={duration}
                onChange={handleProgressChange}
                className="progress-bar"
            />

            <div className="audio-time">
                {Math.floor(progress)} / {Math.floor(duration)} segundos
            </div>

            <select onChange={handleSpeedChange} value={speed} className="speed-selector">
                <option value="0.5">0.5x</option>
                <option value="0.75">0.75x</option>
                <option value="1">1x</option>
                <option value="1.25">1.25x</option>
                <option value="1.5">1.5x</option>
                <option value="2">2x</option>
            </select>

            <audio ref={audioRef} src={audioUrl} style={{display: "none"}}/>


            <button onClick={() => {
                setAutoPlay(!autoPlay)
            }}>
                {autoPlay ? <Auto_play color={on_color}/> : <Auto_play color={off_color}/>}
            </button>
        </div>
    );
};

export default AudioPlayer;